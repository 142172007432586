<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          v-model.number="divisionLocal.sortKey"
                          type="number"
                          clearable
                          label="Sortierschlüssel"
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          v-model.trim="divisionLocal.shortTitle"
                          :counter="6"
                          clearable
                          label="Kurzbezeichnung"
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="divisionLocal.title"
                          :counter="32"
                          label="Bezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-switch
                          v-model="divisionLocal.isActive"
                          dense
                          inset
                          label="Aktiver Dienstgrad"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
export default {
  name: "division-editor",
  props: {
    division: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  model: {
    prop: "division",
    event: "divisionChange",
  },
  computed: {
    divisionLocal: {
      get: function () {
        return this.division;
      },
      set: function (value) {
        this.$emit("divisionChange", value);
      },
    },
  },
};
</script>
